import React, { Component } from "react"
import Fade from "react-reveal/Fade"


import "./Contact.scss"

class ContactInfo extends Component {

  state = {
    fname: '',
    lname: '',
    email: '',
    phone: '',
    company: '',
    message: '',
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  encode = data => {
    return Object.keys(data)
        .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
        .join("&")
  }

  handleSubmit = e => {
    e.preventDefault()

    if (!this.refs.form.checkValidity()) return false

    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: this.encode({
        "form-name": this.refs.form.getAttribute("name"),
        ...this.state
      })
    })
      .then(() => this.setState({result: 'success'}))
      .catch(error => this.setState({result: 'fail', msg: error}))
  }

  render() {
    let props = {
      ref: 'form',
      name: 'contact',
      className: 'form',
      onSubmit: this.handleSubmit,
      'data-netlify': 'true',
      'data-netlify-honeypot': 'bot-field',
    }

    let { content } = this.props


    return (
      <section className="contact">
        <div className="contact__container">
          <div className="contact__container__left">
            <Fade bottom distance="40px">
              <div className="contact__container__heading">Talk to us</div>
            </Fade>
          </div>
          <div className="contact__container__right">
            <Fade bottom distance="40px">
              <div
                className="contact__container__sub-heading"
                dangerouslySetInnerHTML={{ __html: content }}
              />
              <div className="form">
                { this.state.result === 'success' ?
                  (<p className='contact__success'>We have received your enquiry and will get back to you as soon possible. Thanks.</p>) :
                  (<form {...props}>
                    <input
                      className="form__input"
                      name="fname"
                      onChange={this.handleChange}
                      type="text"
                      placeholder="First Name"
                    />
                    <input
                      className="form__input"
                      name="lname"
                      onChange={this.handleChange}
                      type="text"
                      placeholder="Last Name"
                    />
                    <input
                      className="form__input"
                      name="phone"
                      onChange={this.handleChange}
                      type="number"
                      placeholder="Phone"
                    />
                    <input
                      className="form__input"
                      name="email"
                      onChange={this.handleChange}
                      type="email"
                      placeholder="Email"
                    />
                    <input
                      className="form__input"
                      name="company"
                      onChange={this.handleChange}
                      type="text"
                      placeholder="Company"
                    />
                    <textarea
                      className="form__input__message"
                      name="message"
                      onChange={this.handleChange}
                      placeholder="Message or comments"
                    />
                    <button type='submit' className="form__button">Send Message</button>
                  </form>)
                }
              </div>
            </Fade>
          </div>
        </div>
      </section>
    )
  }
}

export default ContactInfo
