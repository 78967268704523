import React from 'react'
import { graphql } from 'gatsby'
import he from 'he'
import Fade from "react-reveal/Fade"

import SEO from '../components/seo'
import Header from '../components/header'
import Footer from '../components/footer'
import BottomBar from '../components/BottomBar'

import ContactInfo from '../components/contact/ContactInfo'

const ContactPage = ({ data }) => {
  const page = data.wpPage
  return (
    <>
      <SEO path="/contact" title={he.decode(page.seo.title)} description={he.decode(page.seo.metaDesc)} />
      <Header isDark />
      <section className="contact">
        <div className="contact__container">
          <div className="contact__container__left">
            <Fade bottom distance="40px">
              <div className="contact__container__heading">Careers</div>
            </Fade>
          </div>
          <div className="contact__container__right full">
            <Fade bottom distance="40px">
              <div
                className="contact__container__sub-heading"
                dangerouslySetInnerHTML={{ __html: page.acf.careers }}
              />
            </Fade>
          </div>
        </div>
      </section>
      <ContactInfo content={page.content} />
      <Footer isCompact />
      <BottomBar />
    </>
  )
}

export const pageQuery = graphql`
  query {
    wpPage(databaseId:{eq:1485}) {
      seo {
        title
        metaDesc
      }
      content
      acf {
        careers
      }
    }
  }
`

export default ContactPage
